import { Controller } from 'stimulus'
import SignaturePad from 'signature_pad'
import 'bootstrap/dist/js/bootstrap'

export default class extends Controller {
  static targets = ['preview', 'dialog', 'pad', 'data', 'applyButton',
                    'applySelector']

  initialize() {
    this.signaturePad = new SignaturePad(this.padTarget)
    window.addEventListener("resize", this.resizeCanvas)
    document.getElementById('signature-dialog').addEventListener('shown.bs.modal', this.resizeCanvas);
    this.resizeCanvas()
  }

  resizeCanvas() {
    var ratio = Math.max(window.devicePixelRatio || 1, 1);
    //fallback to classic query selector to get the stuff we need.
    var canvas = document.getElementById('signature')
    canvas.width = canvas.offsetWidth * ratio;
    canvas.height = canvas.offsetHeight * ratio;
    canvas.getContext('2d').scale(ratio, ratio);
    //this.signaturePad.clear();
  }

  displayPad() {
    //document.getElementById('signature-dialog').modal()
  }

  clearPad() {
    this.signaturePad.clear()
  }

  displayPreview() {
    var src = this.signaturePad.toDataURL();
    this.previewTarget.setAttribute('src', src);
  }

  acceptSignature() {
    var data = this.signaturePad.toDataURL();
    this.dataTarget.value = data
    //document.getElementById('signature-dialog').modal('hide');
    this.displayPreview()
  }

  displaySignatureButton() {
    if (this.applySelectorTarget.value == 'true')
      this.applyButtonTarget.style.display = 'block'
    else {
      this.applyButtonTarget.style.display = 'none'
    }
  }
}
