import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['dollarUnits', 'previousCount', 'addedCount', 'rejectCount',
                    'finalCount', 'returnRejects', 'previousBalance',
                    'addedBalance', 'rejectBalance', 'cassetteBalance']

  calculate() {
    this.finalCountTarget.value = this.totalCount()
    this.previousBalanceTarget.value = this.previousCount * this.dollarUnits
    this.addedBalanceTarget.value = this.addedCount * this.dollarUnits
    this.rejectBalanceTarget.value = this.rejectCount * this.dollarUnits
    this.cassetteBalanceTarget.value = this.totalCount() * this.dollarUnits
  }

  totalCount() {
    var count = 0

    if (this.returnRejectsTarget == 'true') {
      count = this.previousCount - this.addedCount;
    } else {
      count = this.previousCount - this.addedCount + this.rejectCount;
    }

    return count;
  }

  get dollarUnits() {
    var val = parseInt(this.dollarUnitsTarget.value)
    if (isNaN(val)) {
      val = 0
    }

    return val;
  }

  get previousCount() {
    var val = parseInt(this.previousCountTarget.value)
    if (isNaN(val)) {
      val = 0
    }

    return val;
  }

  get addedCount() {
    var val = parseInt(this.addedCountTarget.value)
    if (isNaN(val)) {
      val = 0
    }

    return val;
  }

  get rejectCount() {
   var val = parseInt(this.rejectCountTarget.value)
   if (isNaN(val)) {
    val = 0
   }

   return val;
  }
}
