import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['content', 'toggle', 'close', 'ascending', 'descending']
    static values = { open: Boolean,
        queryString: String}

    connect() {
        this.setInitialArrowStatus()
        this.closeDropdown()
        this.setWhite(this.toggleTarget)
    }

    toggleDropdown() {
        if (this.contentTarget.hidden == true) {
            this.openDropdown()
            this.setGray(this.toggleTarget)
        } else {
            this.closeDropdown()
            this.setWhite(this.toggleTarget)
        }
    }

    openDropdown() {
        this.closeTarget.hidden = false
        this.contentTarget.hidden = false
    }

    closeDropdown() {
        this.closeTarget.hidden = true
        this.contentTarget.hidden = true
        this.setWhite(this.toggleTarget)
    }

    setWhite(btn) {
        btn.classList.add('btn-white')
        btn.classList.remove('btn-secondary')
    }

    setGray(btn) {
        btn.classList.remove('btn-white')
        btn.classList.add('btn-secondary')
    }

    removeHide() {
        this.ascendingTarget.classList.remove('hide')
        this.descendingTarget.classList.remove('hide')
    }

    setInitialArrowStatus() {
        let queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        const directionParam = urlParams.get('sort_direction')
        if (directionParam == "ascending") {
             this.ascendingTarget.classList.add('hide')
        } else if (directionParam == "descending") {
            this.descendingTarget.classList.add('hide')
        }
    }
}