import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['selectBox', 'hiddenFieldArea', 'machineGroupSelect', 'customer']
    preLoadedMachineIds = []

    // Highlights All Selected Machines on page load (only works when editing work orders)
    async connect () {
        this.highlightSelected();
        this.loadExistingMachines();
    }

    // Ran either on page load. Will highlight or de-highlight machines on page load.
    highlightSelected() {
        let machinesChildren = this.hiddenFieldAreaTarget.children;
        let idListArea = document.getElementById("machines")
        let idListHtml = Array.from(idListArea.querySelectorAll('li'))

        let allMachineids = [];

        for(let i=0;i<machinesChildren.length; i++){
            allMachineids.push(machinesChildren[i].value);
        }

        idListHtml.forEach(listItem => {
            if (allMachineids.includes(listItem.value.toString())) {
                listItem.style.fontWeight = "bold"
            } else {
                listItem.style.fontWeight = ""
            }
        })
    }

    // Highlights or un-highlights a machine in the multi-select modal
    // when the id is clicked.
    toggleMultiHighlight(event) {
        if(event.target.style.fontWeight === "bold") {
            event.target.style.fontWeight = ""
        } else {
            event.target.style.fontWeight = "bold"
        }
    }

    // Adds or removes a machine from the list of assigned machines for the
    // work order when the id is clicked in the multi select section.
    toggleMultiSelect(event) {
        let id = event.target.value
        if(id === null || id === "") {
            return
        }

        let userIsAssigned = this.machineAssigned(id)
        if (userIsAssigned) {
            this.unassignmachine(id);
        } else {
            this.assignmachine(id)
        }
    }


    // Given a machine's id, will check if machine is assigned & removes the ID if it's assigned
    unassignmachine(id) {
        if (!this.machineAssigned(id)) {
            return
        }

        this.removeFromHiddenFieldArea(id);
        this.updateMachineCount();
    }

    // Method that actually removes machine ID from hidden form field
    removeFromHiddenFieldArea(id) {
        let hiddenFields = this.hiddenFieldAreaTarget.children
        if (id !== null && id !== '') {
            for(let i=0;i<hiddenFields.length;i++)
            {
                const item = hiddenFields[i];
                if (item.value == id) {
                    item.remove()
                }
            }
        }
    }


    // Given a machine's id, checks the ID exists & adds the Id to hidden machine ID field.
    assignmachine(id) {
        if (id === null || id === "") {
            return
        }

        this.addToHiddenFieldArea(id);
        this.updateMachineCount();
    }

    // Method that actually adds machine ID HTML from hidden form field
    addToHiddenFieldArea(id) {
        this.removeFromHiddenFieldArea(id)    //removes all previous instances of id if present.
        this.hiddenFieldAreaTarget.innerHTML = this.hiddenFieldAreaTarget.innerHTML + this.machineHiddenField(id)
    }

    // Returns a boolean for whether or not a specific machine id has
    // already been assigned to a work order.  It is determining this by
    // the presence/absence of a tag for the machine.
    machineAssigned(id) {
        let value = false;
        const children = this.hiddenFieldAreaTarget.children
        for(let i=0;i<children.length; i++)
        {
            value = children[i].value.includes(id);
            if (value === true) {
                break;
            }
        }
        return value;
    }

    // Given a machine id, returns a hidden field tag that can be placed in the hidden field area
    // to assign the machine to the work order when the form is submitted.
    machineHiddenField(id) {
        if (window.location.href.indexOf("/dispatch") > -1) {
            return `<input type="hidden" name="dispatch_work_order[machine_id_values][]" alt="" value="${id}" />`
        } else if (window.location.href.indexOf("/service") > -1) {
            return `<input type="hidden" name="service_work_order[machine_id_values][]" alt="" value="${id}" />`
        } else if (window.location.href.indexOf("/portal") > -1) {
            return `<input type="hidden" name="portal_work_order[machine_id_values][]" alt="" value="${id}" />`
        }
    }

    // Clears all machines from multi machine select section when section is toggled off. Does not clear previously
    // saved machines.
    // Triggers with event listener tied stimulus controller mult_machine_work_order_controller event
    clearMachines() {
        this.hiddenFieldAreaTarget.innerHTML = '';
        this.preLoadedMachineIds.forEach(MachineId => {
            this.assignmachine(MachineId);
        });
        this.highlightSelected();
    }

    // Loads all previously saved machine IDs from work order
    loadExistingMachines() {
        let preLoadMachineChildren = this.hiddenFieldAreaTarget.children;
        for(let i=0;i<preLoadMachineChildren.length; i++){
            this.preLoadedMachineIds.push(preLoadMachineChildren[i].value);
        }
    }

    // Updates Machine Counter any time machine is removed/added to hidden field area
    updateMachineCount() {
        let counter = document.getElementById('multi-machine-counter');
        if (counter == null) return;

        counter.innerText = this.hiddenFieldAreaTarget.children.length;
    }
}