import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['price', 'select']

  // Sets price field in work log time based on customer billing rate
  set_price() {
    var xhr = new XMLHttpRequest();
    var self = this
    xhr.open('GET', '/customers/'+this.selectTarget.dataset.id+'/bill_groups/' +
                                  this.selectTarget.value)
    xhr.onload = function() {
      if (xhr.status == 200) {
        var price = Number(JSON.parse(xhr.responseText).price).toFixed(2)
        self.priceTarget.value = '$' + price
      } else {
        self.priceTarget.value = "undefined"
      }
    }
    xhr.send()
  }

  // Sets correct bill group based on activity selected in work log time
  // calls set_price to update price based on bill group
  set_bill_group(e) {
    var xhr = new XMLHttpRequest()
    var select = this.selectTarget
    var self = this
    xhr.open('GET', '/change_activity/' + e.currentTarget.value)
    xhr.onload = function() {
      if (xhr.status == 200) {
        var bg = JSON.parse(xhr.responseText).intbillinggroup
        for (var i = 0; i<select.options.length; i++) {
          if( select.options[i].value == bg ) {
            select.options[i].selected = true
            break
          }
        }
        self.set_price()
      }
    }
    xhr.send()
  }
}