import * as React from 'react'

function UserGrid(props) {
    const users = props.users ?? []

    return (<table className="table table-striped table-bordered">
        <thead>
            <tr>
                <th>Name</th>
                <th>Job Title</th>
                <th>Territory</th>
                <th>Mobile #</th>
                <th>Home #</th>
                <th>E-Mail Address</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {users.map(user => {
                return (<tr key={user.id}>
                    <td>{ user.name }</td>
                    <td>{ user.job_title }</td>
                    <td>{ user.territory }</td>
                    <td>{user.phone_number !== null && user.phone_number !== '' ? (
                            <a href={`tel:?${user.phone_number}` || ''} className={"card-text"}>
                                {user.phone_number|| 'Not Available'}
                            </a>) : (
                                'Not Available'
                        )}
                    </td>
                    <td>{user.home_number !== null && user.home_number !== '' ? (
                        <a href={`tel:?${user.home_number}` || ''} className={"card-text"}>
                            {user.home_number|| 'Not Available'}
                        </a>) : (
                        'Not Available'
                    )}</td>
                    <td>{ user.email ?? 'Not Available' }</td>
                    <td>
                        <a className={"btn btn-primary"} href={`/techs/${user.id}`}>View</a> &nbsp;
                        <a className={"btn btn-secondary"} href={`/techs/${user.id}.vcf`}>Download</a>
                    </td>
                </tr>)
            })}
        </tbody>
    </table>)
}

export default UserGrid;