// This controller handles rotating the icon at the far right of collapsible headers.  The actual
// collapsing/expanding is handled via Bootstrap on the DOM level.

import { Controller } from 'stimulus'

export default class extends Controller {

  toggleArrowDown = (e) => {
    e.stopPropagation()
    let downArrow = e.target.lastElementChild
    downArrow.style.transform = e.target.getAttribute("aria-expanded")==="false" ? 'none' : 'rotate(180deg)';
  }

  toggleArrowUp = (e) => {
    e.stopPropagation()
    let upArrow = e.target.lastElementChild
    upArrow.style.transform = e.target.getAttribute("aria-expanded")==="true" ? 'none' : 'rotate(-180deg)';
  }
}

