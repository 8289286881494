import Quagga from 'quagga'
import { Controller } from 'stimulus'
import swal from 'sweetalert2'

export default class extends Controller {
  static targets = ['canvas','scannerArea', 'scanning']

  toggleScanner(){
    if(this.scannerAreaTarget.style.display !='block'){
      this.scannerAreaTarget.style.display = 'block';
      this.load_quagga();
      this.scanningTarget.value = true;
    }
    else
    {
      this.scannerAreaTarget.style.display = 'none';
      Quagga.stop();
      this.scanningTarget.value = false;
    }

  }

  load_quagga(){
    self = this;
    if (this.canvasTarget && navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
      var last_result = [];
      var ScanningDone = false;

      var last_result = [];
      if (Quagga.initialized == undefined) {

        Quagga.onDetected(function(result) {
          var last_code = result.codeResult.code
          last_result.push(last_code);

          self.toggleScanner();

          document.getElementById("search").value = +last_code;
        });

        Quagga.onProcessed(function(result) {
            var drawingCtx = Quagga.canvas.ctx.overlay,
                drawingCanvas = Quagga.canvas.dom.overlay;
            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
                    result.boxes.filter(function (box) {
                        return box !== result.box;
                    }).forEach(function (box) {
                        Quagga.ImageDebug.drawPath(box, {x: 0, y: 1}, drawingCtx, {color: "green", lineWidth: 5});
                    });
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(result.box, {x: 0, y: 1}, drawingCtx, {color: "#00F", lineWidth: 5});
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(result.line, {x: 'x', y: 'y'}, drawingCtx, {color: 'red', lineWidth: 6});
                }
            }
        });
      }

      Quagga.init({
        inputStream : {
          name : "Live",
          type : "LiveStream",
          numOfWorkers: navigator.hardwareConcurrency,
          target: this.canvasTarget
        },
        decoder: {
            readers : ['code_128_reader'],
            multiple : false
        }
      },function(err) {
          if (err) { console.log(err); return }
          Quagga.initialized = true;
          Quagga.start();
      });
    }
  };
}
