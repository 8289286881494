// This controller totals the amount of money coming and going for each specific denomination
// in a cassette swap load.  It does not calculate a cumulative total for all denominations.  That is
// handled by controller cassette_swap_atm_load_controller.js.
//


import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['dollarUnits', 'recycledCount', 'addedCount',  'removedCount', 'rejectedCount',
                    'recycledBalance', 'removedBalance', 'addedBalance', 'rejectedBalance']

  initialize() {
    this.addedBalanceTarget.value = 0
    this.rejectedBalanceTarget.value = 0
    this.removedBalanceTarget.value = 0
    if (this.hasRecycledBalanceTarget) {
      this.recycledBalanceTarget.value = 0
    }
  }

  updateAddedBalance = (e) => {
    let targetElement = e.target.parentElement.parentElement.parentElement.querySelector(".total-added-balance");
    targetElement.value = this.getBalance(e)
    const event = new CustomEvent("update-subtotals")
    window.dispatchEvent(event)
  }

  updateRejectedBalance = (e) => {
    let targetElement = e.target.parentElement.parentElement.parentElement.querySelector(".total-rejected-balance");
    targetElement.value = this.getBalance(e)
    const event = new CustomEvent("update-subtotals")
    window.dispatchEvent(event)
  }

  updateRemovedBalance = (e) => {
    let targetElement = e.target.parentElement.parentElement.parentElement.querySelector(".total-removed-balance");
    targetElement.value = this.getBalance(e)
    const event = new CustomEvent("update-subtotals")
    window.dispatchEvent(event)
  }

  updateRecycledBalance = (e) => {
    let targetElement = e.target.parentElement.parentElement.parentElement.querySelector(".total-recycled-balance");
    targetElement.value = this.getBalance(e)
    const event = new CustomEvent("update-subtotals")
    window.dispatchEvent(event)
  }

  dollarUnits = (e) => {
    var val = parseInt(e.target.parentElement.parentElement.firstElementChild.querySelector('input').value)
    if (isNaN(val)) {
      val = 0
    }

    return val;
  }

  getBalance = (e) => {
    return this.getSelectedCount(e) * this.dollarUnits(e)
  }

  getSelectedCount = (e) => {
    var val = parseInt(e.target.parentElement.querySelector('input').value)
    if (isNaN(val)) {
      val = 0
    }

    return val;
  }
}
