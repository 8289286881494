import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "field", "maxlength", "output" ]

  display_count() {
    this.outputTarget.textContent = this.lengthString
    this.outputTarget.className = this.className + " help-block"
  }

  get fieldLength() {
    return this.fieldTarget.value.length;
  }

  get maxLength() {
    var len = 1000;

    if (this.maxlengthTarget.value != undefined) {
      len = this.maxlengthTarget.value;
    }

    return len;
  }

  get lengthString() {
    return this.fieldLength + "/" + this.maxLength;
  }

  get charsLeft() {
    var current = parseInt(this.fieldLength);
    var max = parseInt(this.maxLength);
    return max - current;
  }

  get className() {
    var name = "";
    var left = this.charsLeft;

    if (left > 15) {
      name = "good-length";
    } else if (left <= 15 && left >= 0) {
      name = "warning-length";
    } else {
      name = "over-length";
    }

    return name;
  }
}
