import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['search','dataGridBody', 'card']

  filter() {
    this.filterCards()
    this.filterGrid()
  }

  filterCards() {
    var cards = this.cardTargets
    for(var i = 0; i < cards.length; i++) {
      var value = cards[i].querySelector('.card-body').innerText.toLowerCase()
      if (value.indexOf(this.query) > -1) {
        cards[i].classList.remove("d-none")
      } else {
        cards[i].classList.add("d-none")
      }
    }
  }

  filterGrid() {
    var rows = this.tableRows;
    for(var i = 0; i < rows.length; i++) {
      var cells = rows[i].querySelectorAll(".data");
      var value = ""

      for(var td = 0; td < cells.length; td++) {
        value += cells[td].innerText.toLowerCase();
      }

      if (value.indexOf(this.query) > -1) {
        rows[i].style.display = "";
      } else {
        rows[i].style.display = "none"
      }
    }
  }


  get query() {
    return this.searchTarget.value.toLowerCase();
  }

  get tableRows() {
    var rows = []
    var grids = this.dataGridBodyTargets
    for (var i = 0; i < grids.length; i++) {
      var tableRows = grids[i].getElementsByTagName("tr");
      for (var x = 0; x < tableRows.length; x++) {
        rows = ([...rows, tableRows[x]]);
      }
    }

    return rows;
  }
}
