import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['singleMachineId', 'multiMachineSelectBool', 'multiMachineSelect', 'multipleMachinesField', 'hiddenFieldArea']

  // Runs on page load
  initialize() {
    if (this.multiMachineSelectBoolTarget.value === 'true'){
      this.toggleOnMultiMachine();
    }
  }

  // Called on click or page load in order to toggle multi machines feature to correct configuration
  toggleMultiMachine() {
    if (this.multiMachineSelectBoolTarget.value === 'true'){
      this.toggleOffMultiMachine();
    } else {
      this.toggleOnMultiMachine();
    }
  }

  // Toggles Multi Machine Selection Area On While Disabling Current Single Machine Select Field
  toggleOnMultiMachine() {
    this.singleMachineIdTarget.value = '';
    this.singleMachineIdTarget.classList.add('hidden');
    this.multipleMachinesFieldTarget.classList.remove('hidden');
    this.multiMachineSelectTarget.classList.remove('hidden');
    this.multiMachineSelectBoolTarget.value = true;
    this.multiMachineSelectTarget.scrollIntoView({behavior: "smooth"});
  }

  // Toggles Multi Machine Selection Area OFF While Enabling Single Machine Select Field
  toggleOffMultiMachine() {
    this.singleMachineIdTarget.value = '';
    this.singleMachineIdTarget.classList.remove('hidden');
    this.multipleMachinesFieldTarget.classList.add('hidden');
    this.multiMachineSelectTarget.classList.add('hidden');
    this.multiMachineSelectBoolTarget.value = false;
    const event = new CustomEvent("clear-machines")
    window.dispatchEvent(event)
  }
}
