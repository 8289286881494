import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['messageSelector', 'message', 'messagePreview']

  display_message() {
    if (this.messageSelectorTarget.value == 'Custom') {
      this.messageTarget.type = 'text'
    }
    else {
      this.messageTarget.type = 'hidden'
      this.messageTarget.value = this.messageSelectorTarget.value
      this.messagePreviewTarget.innerHTML = this.messageSelectorTarget.value
    }
  }
}
