import * as React from 'react'
import {useEffect, useState} from "react";
import Cookies from 'js-cookie'

function ViewSwitcher(props) {
    const [selectedView, setSelectedView] = useState('cards');

    useEffect(() => {
        setSelectedView(props.view)
    }, [props.view])

    const handleChange = (e) => {
        props.onChange(e)
    }

    const cardClass = () => {
        return selectedView === 'cards' ? 'btn btn-secondary' : 'btn btn-white'
    }

    const tableClass = () => {
        return selectedView === 'cards' ? 'btn btn-white' : 'btn btn-secondary'
    }

    const updateView = (v) => {
        if (v === selectedView) return;
        let selected = v === 'cards' ? 'cards' : 'table';
        setSelectedView(selected)
        handleChange(selected)
    }

    return (<div className="btn-group" role="group" aria-label="Example">
        <button type="button" onClick={e => updateView('cards')} className={cardClass()}>
            <i className="fas fa-th-large"></i>
        </button>
        <button type="button" onClick={e => updateView('table')} className={tableClass()}>
            <i className="fas fa-list"></i>
        </button>
    </div>)
}

export default ViewSwitcher;