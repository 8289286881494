import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['player']

  navigate() {
    var url = this.playerTarget.dataset.href
    window.location = url
  }
}
