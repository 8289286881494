import * as React from 'react'

function GenericFilter(props) {
    const handleChange = (e) => {
        props.onChange(e.target.value)
    }

    return (<div className="card bg-light">
        <div className="card-body">
            <div className="mb-3">
                <label className="form-label">Search Terms</label>
                <input className="form-control" onKeyUp={handleChange} />
            </div>
        </div>
    </div>)
}

export default GenericFilter;