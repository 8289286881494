import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['selectBox', 'hiddenFieldArea', 'tagArea', 'techGroupSelect', 'customer']

    techs = []

    // Assigns Techs to work order automatically when form is loaded (Only Loads on EDIT, Not on NEW)
    connect() {
        this.clearPreviousTags();

        let xhr = new XMLHttpRequest();
        let self = this
        xhr.open('GET', '/techs/list')
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhr.onload = function() {
            if (xhr.status === 200) {
                self.techs = JSON.parse(xhr.responseText)

                let preSelected = self.hiddenFieldAreaTarget.children
                for (let i = 0; i < preSelected.length; i++) {
                    const tech = self.techs.find(q => q.id === parseInt(preSelected[i].value))
                    if (tech != null && !self.userAssigned(tech.name)) {
                        self.addBadge(tech.name)
                    }
                }
            }
        }
        xhr.send()

        const hiddenFields = this.hiddenFieldAreaTarget.children;
        if(hiddenFields.length === 0) {
            this.assignUsers()
        }
    }


    // Upon opening the multi-select modal, make sure that all
    // selected techs are highlighted and no unselected ones are.
    highlightSelected() {
        let nameList = document.getElementById("techsAssignModalBody")
        let nameListItems = Array.from(nameList.querySelectorAll('li'))

        let allBadges = Array.from(this.tagAreaTarget.querySelectorAll('span'))
        let allBadgeNames = allBadges.map(badge => badge.innerText.slice(2))

        nameListItems.forEach(listItem => {
            if (allBadgeNames.includes(listItem.innerText)) {
                listItem.style.fontWeight = "bold"
            } else {
                listItem.style.fontWeight = ""
            }
        })
    }


    // Assigns Techs Entered in the Tech Select Box
    addSelectBoxTech() {
        let name = this.selectBoxTarget.value
        this.selectBoxTarget.value = '';
        if (name==null || name.trim()==="") {
            return
        }

        name = name.trim()
        let tech = this.findTech(name)
        if (tech === undefined) {
            return
        }

        this.assignTech(name)
    }


    // Highlights or un-highlights a tech in the multi-select modal
    // when the name is clicked.
    toggleMultiHighlight(event) {
        if(event.target.style.fontWeight === "bold") {
            event.target.style.fontWeight = ""
        } else {
            event.target.style.fontWeight = "bold"
        }
    }

    // Adds or removes a tech from the list of assigned techs for the
    // work order when the name is clicked on the multiselect menu.
    toggleMultiSelect(event) {
        let name = event.target.innerText
        if(name === null || name === "") {
            return
        }

        let userIsAssigned = this.userAssigned(name)
        if (userIsAssigned) {
            this.unassignTech(name)
        } else {
            this.assignTech(name)
        }
    }


    // Given a tech's name, hide their badge and remove them from the hidden field area.
    unassignTech(name) {
        if (!this.userAssigned(name)) {
            return
        }

        this.removeBadge(name)
        this.removeFromHiddenFieldArea(name)
    }


    // Removes the badge from the tagArea with the tech name parameter passed.
    removeBadge(name) {
        let allBadges = Array.from(this.tagAreaTarget.querySelectorAll('span'))
        if(allBadges.length === 0) {
            return
        }
        let badgesToRemove = allBadges.filter(badge => badge.innerText.includes(name))
        badgesToRemove.forEach(badge => badge.remove())
    }


    // Given a tech's name, remove their hidden input field from the
    // hidden field area.
    removeFromHiddenFieldArea(name) {
        let hiddenFields = this.hiddenFieldAreaTarget.children
        let tech = this.findTech(name)
        if (tech !== null && tech !== undefined) {
            let techId = tech.id.toString()
            for(let i=0;i<hiddenFields.length;i++)
            {
                const item = hiddenFields[i];
                if (item.value === techId) {
                    item.remove()
                }
            }
        }
    }


    // Given a tech's name, add a badge for them to the badge
    // area and their id to the hidden field input area.
    assignTech(name) {
        let tech = this.findTech(name)
        if (name === null || name.trim() === "" || tech === undefined) {
            return
        }

        this.addToHiddenFieldArea(name)
        this.addBadge(name)
    }


    // Given a tech's name, add a badge for them in the tag area.
    addBadge(name) {
        if(this.userAssigned(name)) {
            return
        }
        this.tagAreaTarget.innerHTML = this.tagAreaTarget.innerHTML + this.techBadge(name);
    }


    // Given a tech's name, add them to the hidden field area.
    addToHiddenFieldArea(name) {
        this.removeFromHiddenFieldArea(name)    //removes all previous instances of name if present.
        let techId = this.findTech(name).id
        this.hiddenFieldAreaTarget.innerHTML = this.hiddenFieldAreaTarget.innerHTML + this.techHiddenField(techId)
    }


    // Removes Tech when their X is clicked
    removeTech(event) {
        const name = event.target.parentElement.innerText.substring(2);
        this.unassignTech(name)
    }


    // Returns a boolean for whether or not a specific tech name has
    // already been assigned to a work order.  It is determining this by
    // the presence/absence of a tag for the user.
    userAssigned(name) {
        let value = false;
        const children = this.tagAreaTarget.children
        for(let i=0;i<children.length;i++)
        {
            value = children[i].innerText.includes(name);
            if (value === true) {
                break;
            }
        }
        return value;
    }


    // Given a tech name, returns an object that contains both their
    // id number and their name.
    findTech(name) {
        return this.techs.find(q => q.name === name)
    }


    // returns a badge HTML Object for a tech's name.
    techBadge(name) {
        return `<span class="badge bg-primary m-1"><a data-action="click->work-order-tech-select#removeTech">&times;</a> ${name}</span>`
    }


    // Given a tech id, returns a hidden field tag that can be placed in the hidden field area
    // to assign the tech to the work order when the form is submitted.
    techHiddenField(id) {
        if (window.location.href.indexOf("/dispatch") > -1) {
            return `<input type="hidden" name="dispatch_work_order[tech_id_values][]" alt="" value="${id}" />`
        } else if (window.location.href.indexOf("/service") > -1) {
            return `<input type="hidden" name="service_work_order[tech_id_values][]" alt="" value="${id}" />`
        } else if (window.location.href.indexOf("/portal") > -1) {
            return `<input type="hidden" name="portal_work_order[tech_id_values][]" alt="" value="${id}" />`
        }
    }

    // Assigns Techs to work order automatically when route changes on New/Edit Work Orders
    assignUsers() {
        if (this.hasTechGroupSelectTarget) {

            let self = this
            let techs = []
            let id = this.techGroupSelectTarget.value
            var customer = this.customerTarget.value

            self.clearPreviousSelections();

            if (id !== '' && id !== null) {
                let xhr = new XMLHttpRequest();
                xhr.open('GET', `/route_tech_groups/${id}/${customer}`)
                xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        techs = JSON.parse(xhr.responseText)

                        for (let i = 0; i < techs.length; i++) {
                            const tech = techs[i]
                            self.assignTech(tech.name)
                        }
                    }
                }
                xhr.send();
            }
        }
    }

    // Clears assigned techs when new WO route is selected
    clearPreviousSelections() {
        this.tagAreaTarget.innerHTML = '';
        this.hiddenFieldAreaTarget.innerHTML = '';
    }

    // Clears assigned techs tags only upon page load
    clearPreviousTags() {
        this.tagAreaTarget.innerHTML = '';
    }
}