import { Controller } from 'stimulus'
import swal from 'sweetalert2'

export default class extends Controller {
  static targets = ['commentsWrapper', 'commentsBox']

  checkComments () {
    var comment = this.commentsBoxTarget.value;

    var change_text = 'You are selecting to enter comments later and you have' +
      ' entered comments. By clicking okay those will be removed and unable' +
      ' to be recovered. This work order will still appear in your list' +
      ' until you add comments.';

    if(comment.length > 0) {
      new swal({ icon: 'warning',
             title: 'Warning',
             text: change_text,
             showCancelButton: true
           }).then((result) => {
            if(result.value) {
              this.commentsBoxTarget.value = '';
            } else {
              this.commentsWrapperTarget.style.display = ''
            }
           })
    }

    if (this.commentsWrapperTarget.style.display == '') {
      this.commentsWrapperTarget.style.display = 'none'
    } else {
      this.commentsWrapperTarget.style.display = ''
    }
  }
}
