// Extra functionality for the Create Machine page.

import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
    static targets = ['nextAssetNumber', 'assetNumber', 'mmid', 'select', 'prevFirmware', 'thirdParty', 'thirdPartySelection', 'notOurs', 'gaming', 'datePlaced', 'selectChangeDate', 'datePlacedSubmit']

    connect() {
        this.loadFirmwareInfo()
        this.initializeFlatpickr()
        this.initialThirdPartyShowStatus()
        this.setPlacedDate()
    }

    setPlacedDate() {
        let today = this.getFormattedDate(new Date())
        this.datePlacedSubmitTarget.value = today
        this.datePlacedTarget.innerText = today
    }

    getFormattedDate(date) {
        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return month + '/' + day + '/' + year;
    }

    async applyNextAssetNumber() {
        // The next asset number is stored in Amusement::Option in the next_sm_asset_num
        // field. Upon clicking the asset number button and triggering this method. The XML
        // request will retrieve the current asset # while iterating & saving a new one.
        const context = this

        var xhr = new XMLHttpRequest()
        xhr.open('GET', `/machine_master/${context.mmidTarget.value}/asset_number`)
        xhr.onload = await function() {
            if (xhr.status == 200) {
                let asset_number = JSON.parse(xhr.responseText)
                context.assetNumberTarget.value = asset_number
            } else {
                console.log("Something went wrong with the asset_number query in machine_master_controller.")
            }
        }
        xhr.send()
    }

    updateFirmwareInfo() {
        // called when machine_master is changed.

        this.removeOldValues();
        this.loadFirmwareInfo();
        this.updateGamingMachineLabel()
    }

    async updateGamingMachineLabel() {
        // The label "Gaming Machine" should show when the machine master has
        // a machine class that is gaming_only.
        const context = this

        var xhr = new XMLHttpRequest()
        xhr.open('GET', `/machine_master/${context.mmidTarget.value}/gaming_only`)
        xhr.onload = await function() {
            if (xhr.status == 200) {
                let gaming_only = JSON.parse(xhr.responseText).gaming_only
                if (gaming_only === true) {
                    context.gamingTarget.classList.remove('hidden')
                } else {
                    context.gamingTarget.classList.add('hidden')
                }
            } else {
                console.log("Something went wrong with the gaming_only query in updateGamingMachineLabel.")
            }

        }

        // If there is no selection for the Machine Master, don't show the Gaming Machine label.
        // Remove it if already present.
        if (this.mmidTarget.value) {
            xhr.send()
        } else {
            this.gamingTarget.classList.add('hidden')
        }
    }

    loadFirmwareInfo() {
        // Loads the firmware data into the collection_select form field for firmware.
        // The firmware selection drop-down is populated based on what the machine master is.

        if (this.mmidTarget.value == '') {
            return
        }

        var context = this
        var xhr = new XMLHttpRequest()
        xhr.open('GET', `/machine_firmware/available_for_machine/${this.mmidTarget.value}`)
        xhr.onload = function() {
            if (xhr.status == 200) {
                let prevFirmwareId = context.prevFirmwareTarget.innerText
                var firmwares = JSON.parse(xhr.responseText)
                firmwares.forEach(function(firmware) {
                    let newFirmwareElement = document.createElement('option')
                    if (firmware.id == prevFirmwareId) {
                        newFirmwareElement.setAttribute('selected', 'selected')
                    }

                    newFirmwareElement.code = firmware.code ? firmware.code : '-no code-'
                    newFirmwareElement.full_descr = firmware.full_descr ? firmware.full_descr : '-no description-'
                    newFirmwareElement.value = firmware.id
                    newFirmwareElement.innerText = `${firmware.code}: ${firmware.full_descr}`
                    this.selectTarget.appendChild(newFirmwareElement)
                }, context)
            } else {
            }}

        xhr.send()
    }


    removeOldValues() {
        // the firmware selection drop-down is populated based on what the machine master is.  No
        // values for other machine masters should be present.

        // clear out the old values for the machine firmware whenever the machine master is changed
        // or the page was reloaded.
        var e = this.selectTarget
        var first = e.firstElementChild;
        while (first) {
            first.remove();
            first = e.firstElementChild;
        }

        // Add a blank option to be able to select from.
        let newEle = document.createElement('option')
        newEle.value = ''
        e.appendChild(newEle)
    }


    initializeFlatpickr() {
        // this flatpickr is used for the software_change_date field in the form.  It should not
        // have any date to start out, but then if the form is re-rendered, it should default to the
        // previously selected date.

        let date_options = { dateFormat: 'm/d/Y', ariaDateFormat: 'm/d/Y' }
        flatpickr( this.selectChangeDateTarget, date_options );
    }

    showHideThirdParty() {
        // When the "Not Our Machine" checkbox is checked, the third party
        // customer id's should be visible.  If it is unchecked, then the
        // selection should not be visible and any previously selected item
        // is unselected.


        const thirdPartyClasses = this.thirdPartyTarget.classList
        thirdPartyClasses.toggle("hidden")

        // uncheck all selections if we are hiding the selection box
        if (thirdPartyClasses.contains("hidden")) {
            this.thirdPartySelectionTarget.selectedIndex = "-1";
        }
    }

    initialThirdPartyShowStatus() {
        // Upon loading or re-loading the page, correctly determine if the third-party
        // list of customers should be hidden or shown.

        if( this.notOursTarget.checked ) {
            this.thirdPartyTarget.classList.remove("hidden")
        } else {
            this.thirdPartyTarget.classList.add("hidden")
        }
    }
}