/* eslint no-console:0 */
import ReactDOM from "react-dom";
import React from 'react'
import '../legacy/custom_confirm';
import "vanilla-nested";
import {loadDates} from "../legacy/ui-bindings";
import '../legacy/legacy/work_logs'

const Turbolinks = require('turbolinks');
Turbolinks.start();

import Rails from 'rails-ujs'
Rails.start()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
const application = Application.start()
const context = require.context("../controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

// import images
import '../images/app_logo.png'
import '../images/arrow.png'
import '../images/mobile_accept.png'
import '../images/mobile_cancel.png'
import '../images/top_arr.png'

import '@fortawesome/fontawesome-free/js/all'

// React Pages
import PhoneDirectory from "../components/PhoneDirectory";
import {bindWorkLogTimes} from "../legacy/legacy/work_logs";

document.addEventListener('turbolinks:load', () => {
    loadDates();
    const element = document.getElementById("phone-directory");
    if(element) {
        ReactDOM.render(<PhoneDirectory />, element)
    }

    //document.getElementsByName('html')[0].style.border = 'none';

    if (navigator.userAgent.includes("[Smart Mobile Android App]")) {
        document.getElementsByClassName('.top-brand')[0].style.display = 'none'
    }

    const itemFinderBtn = document.getElementById('item-finder-btn');

    if (itemFinderBtn) {
        document.getElementById('item-finder-btn').addEventListener('click', function(event) {
            event.preventDefault()
            document.getElementById('item-search-modal').modal()
        })
    }

    const hoursContainer = document.getElementById('hours')

    if (hoursContainer) {
        hoursContainer.addEventListener('show.bs.collapse', function() {
            document.getElementById('hours-btn').innerText = 'Hide Hours of Operation';
        })

        hoursContainer.addEventListener('hide.bs.collapse', function() {
            document.getElementById('hours-btn').innerText = 'Show Hours of Operation';
        })
    }
    const timesContainer = document.getElementById('times');

    if (timesContainer) {
        document.addEventListener('vanilla-nested:fields-added', bindWorkLogTimes)
    }

})

