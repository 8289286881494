import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selector']

  change_session() {
    var url = '/rewards/sessions/' + this.selectorTarget.value + '/update'
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url);
    xhr.setRequestHeader('Content-Type', 'application/javascript')
    xhr.onload = function() {
      if (xhr.status === 200) {
        eval(xhr.responseText)
      }
    }
    xhr.send()
  }
}
