import Swal from 'sweetalert2'
import { Controller } from 'stimulus'

export default class WorkOrderMachineAssignment extends Controller {
    static targets = ['machineId']

    new_machine_id () {
        this.verify_machine();
    }

    async verify_machine() {
        let not_our_machine = await this.not_our_machine_verification()
        let others_provide_service = await this.others_provide_service_verification()

        if (not_our_machine === "true") {
            await Swal.fire("Not Our Machine", "This is not our machine.  Please contact the appropriate third party for service.", "warning" )
        }
        if (others_provide_service === "true") {
            await Swal.fire("Others Provide Service", "We do not service or maintain this machine. Please contact the appropriate third party for service.", "warning")
        }
    }

    async not_our_machine_verification() {
        // Each machine has the property Machine.not_our_machine, a boolean.
        // Here, we are reaching out to the view verify_our_machine, which will
        // return "true" if .not_our_machine is true, and "false" if it is false.
        let id = this.machineIdTarget.value;
        if (!id) {
            return
        }
        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/not_our_machine/${id}`, false)
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

        await xhr.send()
        return xhr.responseText
    }


    async others_provide_service_verification() {
        // Each machine has the property Machine.others_provide_service, a boolean.
        // Here, we are reaching out to the view others_provide_service, which will
        // return "true" if .others_provide_service is true, and "false" if it is false.
        let id = this.machineIdTarget.value;
        if (!id) {
            return
        }

        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/others_provide_service/${id}`, false)
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

        await xhr.send()
        return xhr.responseText
    }
}
