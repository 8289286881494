// This controller totals the amount of money coming and going for each specific denomination
// in an atm load.  It does not calculate a cumulative total for all denominations.  That is
// handled by controller complete_atm_load_controller.js.
//

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['dollarUnits', 'previousCount', 'addedCount', 'rejectCount',
                    'finalCount', 'returnRejects', 'previousBalance',
                    'addedBalance', 'rejectBalance', 'cassetteBalance']

  connect() {
    this.calculate()
  }

  calculate() {
    this.finalCountTarget.value = this.totalCount()
    this.previousBalanceTarget.value = this.previousCount * this.dollarUnits
    this.addedBalanceTarget.value = this.addedCount * this.dollarUnits
    this.rejectBalanceTarget.value = this.rejectCount * this.dollarUnits
    this.cassetteBalanceTarget.value = this.totalCount() * this.dollarUnits
    const event = new CustomEvent("update-subtotals")
    window.dispatchEvent(event)
  }

  totalCount() {
    var count = 0

    if (this.returnRejectsTarget.value == 'true') {
      count = this.previousCount + this.addedCount;
    } else {
      count = this.previousCount + this.addedCount + this.rejectCount;
    }

    return count;
  }

  get dollarUnits() {
    var val = parseInt(this.dollarUnitsTarget.value)
    if (isNaN(val)) {
      val = 0
    }

    return val;
  }

  get previousCount() {
    var val = parseInt(this.previousCountTarget.value)
    if (isNaN(val)) {
      val = 0
    }

    return val;
  }

  get addedCount() {
    var val = parseInt(this.addedCountTarget.value)
    if (isNaN(val)) {
      val = 0
    }

    return val;
  }

  get rejectCount() {
   var val = parseInt(this.rejectCountTarget.value)
   if (isNaN(val)) {
    val = 0
   }

   return val;
  }
}
