import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['id', 'description', 'price', 'extendedPrice',
                    'qty']

  choose(e) {
    var details = e.currentTarget.dataset
    this.idTarget.value = details.id;
    this.descriptionTarget.value = details.description;
    this.priceTarget.value = details.price;
    this.extendedPriceTarget.value = details.price;
    this.qtyTarget.value = 1
    document.getElementById('item-search-modal').modal('hide');
  }
}
