import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ['workOrderType']

    updatePriority() {
        var xhr = new XMLHttpRequest();
        xhr.open('GET', `/work_order_types/${this.workOrderTypeTarget.value}`)
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhr.onload = function() {
            if (xhr.status == 200) {
                eval(xhr.responseText)
            }
        }
        xhr.send()
    }
}