import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
    static targets = ['startTime', 'endTime']

    time_options = {
        enableTime: true,
        enableSeconds: true,
        noCalendar: true,
        dateFormat: "h:i:S K"
    }

    connect() {
        this.startTimeTargets.forEach(startTime => {
            flatpickr(startTime, this.time_options);
        });

        this.endTimeTargets.forEach(endTime => {
            flatpickr(endTime, this.time_options);
        });
    }

    formatTime(target){

    }
}
