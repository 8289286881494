import flatpickr from "flatpickr";

var date = new Date

function minutes(num) {
    var array = [0, -1, -2, -3, -4, -5, -6, -7, 7, 6, 5, 4, 3, 2, 1, 0, -1, -2,
        -3, -4, -5, -6, -7, 7, 6, 5, 4, 3, 2, 1, 0, -1, -2, -3, -4, -5,
        -6, -7, 7, 6, 5, 4, 3, 2, 1, 0, -1, -2, -3, -4, -5, -6, -7, 7, 6,
        5, 4, 3, 2, 1]
    var min = array[num] + num
    if (min == 60) {
        min -= 60
    }
    return min.toString()
}

function hours(num) {
    var hour = 0
    if (num == 0) {
        hour = 12
    } else if (num > 12) {
        hour = num - 12
    } else {
        hour = num
    }
    return hour
}

function amPm(num) {
    var str = ''
    if (num < 12) {
        str = 'AM'
    } else {
        str = 'PM'
    }
    return str
}

var time_options = {
    enableTime: true, noCalendar: true, dateFormat: 'h:i K',
    minuteIncrement: 15, defaultHour: 12, defaultMinute: 0
}

export function bindWorkLogTimes(event) {
    var date = new Date()
    var hour = hours(date.getHours())
    var minute = minutes(date.getMinutes())
    if (amPm(date.getHours()) == "PM") {
        hour += 12
    }
    time_options['defaultHour'] = hour
    time_options['defaultMinute'] = minute
    var field = event.target
    var startTimeField = field.getElementsByClassName('start_time')[0]
    var endTimeField = field.getElementsByClassName('end_time')[0]
    flatpickr(startTimeField, time_options)
    flatpickr(endTimeField, time_options)
}

