// The purpose of this controller is to evaluate, decorate with appropriate colors, and
// to finally submit the cumulative totals for a cassette swap.  It uses the amount per denomination
// that is calculated by the controller "swap_cassette_controller.js" to come up with these values.
//

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['loadAmount', 'recycleAmount', 'rejectAmount',
                    'removedAmount', 'totalLoadAmount', 'totalRejectedAmount',
                    'totalRecycleDollars', 'plannedLoadBalance',
                    'countInRemovedCassette', ]

  connect() {
    this.calculate()
  }

  calculate() {
    this.totalLoadAmountTarget.value = this.calculatedFinalBalance()
    this.totalRejectedAmountTarget.value = this.calculatedRejectedBalance()
    if (this.hasTotalRecycleDollarsTarget) {
      this.totalRecycleDollarsTarget.value = this.calculatedRecycleBalance()
    }
    if (this.hasCountInRemovedCassetteTarget) {
      this.countInRemovedCassetteTarget.value = this.calculatedRemovedBalance()
    }
    this.borderColor()
  }

  calculatedRejectedBalance() {
    return this.calculatedBalance(this.rejectAmountTargets)
  }

  calculatedRecycleBalance() {
    return this.calculatedBalance(this.recycleAmountTargets)
  }

  calculatedRemovedBalance() {
    return this.calculatedBalance(this.removedAmountTargets)
  }

  calculatedFinalBalance() {
    return this.calculatedBalance(this.loadAmountTargets)
  }

  borderColor() {
    var planned_amount = parseInt(this.plannedLoadBalanceTarget.value)
    var load_fields = document.getElementsByClassName('load-count')
    if (planned_amount == this.calculatedFinalBalance()) {
      document.getElementsByTagName('body')[0].style.border = 'solid 0.9em green'
      for (let i = 0; i < load_fields.length; i++){
        load_fields[i].style.backgroundColor = 'rgb(0, 255, 0, 0.25)'
      }
    } else {
      document.getElementsByTagName('body')[0].style.border = 'solid 0.9em red'
      for (let i = 0; i < load_fields.length; i++){
        load_fields[i].style.backgroundColor = 'rgb(255, 1, 0, 0.25)'
      }
    }
  }

  calculatedBalance(array) {
    var amount = 0
    var count = array.length

    for(var i = 0; i < count; i++) {
      var unitAmount = parseInt(array[i].value)
      if (!isNaN(unitAmount)) {
        amount += unitAmount
      }
    }

    return amount;
  }
}
