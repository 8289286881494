import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['complete', 'additionalFields']

    showFields() {
        if (this.completeTarget.value == 'true') {
            this.additionalFieldsTarget.style.display = 'block';
        } else {
            this.additionalFieldsTarget.style.display = 'none';
        }
    }
}