import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = []

  dial() {
    send_alerts()
    window.location = "tel:911"
  }

  send_alerts() {

  }
}
