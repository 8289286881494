import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['denomination', 'count', 'subtotal']

  calculate() {
    this.subtotalTarget.value = this.denomination * this.count
  }

  get denomination() {
    return parseInt(this.denominationTarget.value)
  }

  get count() {
    return parseInt(this.countTarget.value)
  }
}
