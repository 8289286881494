// On the Open ATM Loads page, the default for the load groups is
// for them to be shown.  This closes them all and then opens
// the ones that have a "Map" button.  Starting them open is needed
// so that they do not require two clicks to get the arrows to turn.

import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['body', 'head']

  initialize() {
    this.applyCollapse(this.bodyTargets)
  }

  async applyCollapse(element) {
    await this.headTarget.click()

    var allLinkTexts = Array.from(this.bodyTarget.querySelectorAll('a')).map(link => link.innerText)
    if (allLinkTexts.includes('Map')) {
      this.bodyTarget.classList.add('show')
      this.headTarget.setAttribute("aria-expanded", "true")
    }
    let upArrow = this.headTarget.lastElementChild
    upArrow.style.transform = this.headTarget.getAttribute("aria-expanded")==="true" ? 'none' : 'rotate(-180deg)';
  }
}
