import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['techGroupSelect', 'tagArea', 'hiddenFieldArea']


    assignUsers(event) {
        let self = this
        let techs = []
        let id = event.target.value
        if (id !== '' && id !== null) {
            let xhr = new XMLHttpRequest();
            xhr.open('GET', `/tech_groups/${id}`)
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
            xhr.onload = function() {
                if (xhr.status === 200) {
                    techs = JSON.parse(xhr.responseText)

                    if (techs.length > 0) {
                        self.clearPreviousSelections();
                    }

                    for(let i=0;i<techs.length;i++)
                    {
                        const tech = techs[i]
                        self.tagAreaTarget.innerHTML = self.tagAreaTarget.innerHTML + self.techBadge(tech.name);
                        self.hiddenFieldAreaTarget.innerHTML = self.hiddenFieldAreaTarget.innerHTML + self.techHiddenField(tech.id)
                    }
                }
            }
            xhr.send();
        }
    }

    clearPreviousSelections() {
        this.tagAreaTarget.innerHTML = '';
        this.hiddenFieldAreaTarget.innerHTML = '';
    }

    techBadge(name) {
        return `<span class="badge bg-primary"><a data-action="click->tech-select#removeTech">&times;</a> ${name}</span> &nbsp;`
    }

    techHiddenField(id) {
        return `<input type="hidden" name="sales_interaction_event[tech_id_values][]" alt="" value="${id}" />`
    }
}