import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['content', 'toggle', 'close']
    static values = { open: Boolean }
    connect() {
        this.closeDropdown()
        this.openDropdownIfSearchPresent()
    }

    toggleDropdown() {
        try {
            if (this.contentTarget.hidden == true) {
                this.openDropdown()
            } else {
                this.closeDropdown()
            }
        } catch {}
    }

    openDropdown() {
        this.closeTarget.hidden = false
        this.contentTarget.hidden = false
        this.toggleGray()
    }

    closeDropdown() {
        this.closeTarget.hidden = true
        this.contentTarget.hidden = true
        this.toggleWhite()
    }

    openDropdownIfSearchPresent() {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString);
        if (urlParams.has('search') && urlParams.get('search').length > 0) {
            this.openDropdown()
        }
    }

    toggleWhite() {
        this.toggleTarget.classList.add('btn-white')
        this.toggleTarget.classList.remove('btn-secondary')
    }

    toggleGray() {
        this.toggleTarget.classList.remove('btn-white')
        this.toggleTarget.classList.add('btn-secondary')
    }

}