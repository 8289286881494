import * as React from 'react'
import GenericFilter from "./filters/GenericFilter";
import {useEffect, useState} from "react";
import axios from "axios";
import ViewSwitcher from "./utilities/ViewSwitcher";
import PhoneDirectoryCards from "./PhoneDirectoryCards";
import UserGrid from "./grids/UserGrid";

function PhoneDirectory() {
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [view, setView] = useState('cards');
    const [users, setUsers] = useState([])

    useEffect(async () => {
        const result = await axios.get('/api/v1/techs', {headers: {Authorization: '523380844e17ea69e1f28453bb62e62d'}});

        setUsers(result.data);
        setFilteredUsers(result.data);
    }, [])

    const filterUsers = (e) => {
        setFilteredUsers(users.filter(x => userFilter(e,x)));
    }

    const selectView = (e) => setView(e);

    const output = () => {
        return view === 'cards' ? <PhoneDirectoryCards users={filteredUsers} /> : <UserGrid users={filteredUsers} />
    }

    return (<div>
        <h2>Phone Directory</h2>
        <GenericFilter onChange={e => filterUsers(e)} />
        <ViewSwitcher view="cards" onChange={e => selectView(e)} /><br /><br />
        { output() }
    </div>)
}

export default PhoneDirectory;

function userFilter(searchTerm, user) {
    let searchString = `${user.name}${user.job_title}${user.territory}${user.phone_number}${user.email}${user.home_phone}`.toLowerCase();
    return searchString.includes(searchTerm.toLowerCase());
}