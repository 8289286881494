import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
    static targets = ['startDate', 'endDate']

    date_options = { dateFormat: 'm/d/Y', ariaDateFormat: 'm/d/Y' }

    connect() {
        flatpickr(this.startDateTarget, this.date_options);
        flatpickr(this.endDateTarget, this.date_options);
    }
}
