import * as React from 'react'

function UserCard(props) {
    return <div className="result-card mb-3" style={{width: 320, maxHeight: '100%', minHeight: '100%'}}>
        <div className={"card bg-light text-wrap"}  style={{height: '100%'}}>
            <img className={"card-img-top"} src={props.user.photo} />
            <div className="card-body">
                <h3 className={"card-title"}>{props.user.name }</h3>
                <h4 className={"card-subtitle"}>{props.user.job_title}</h4>
                <p className={"card-text"}>
                    <em>Territory: </em>
                    {props.user.territory}
                </p>
                <p className={"card-text"}>
                    <em>Mobile #: </em>
                    {props.user.phone_number !== null && props.user.phone_number !== '' ? (
                        <a href={`tel:?${props.user.phone_number}` || ''} className={"card-text"}>
                            {props.user.phone_number || 'Not Available'}
                        </a>) : (
                        'Not Available'
                    )}
                </p>
                <p className={"card-text"}>
                    <em>Home #: </em>
                    {props.user.home_number !== null && props.user.home_number !== '' ? (
                        <a href={`tel:?${props.user.home_number}` || ''} className={"card-text"}>
                            {props.user.home_number || 'Not Available'}
                        </a>) : (
                        'Not Available'
                    )}
                </p>
                <p className={"card-text"}>
                    <em>Email: </em>
                    { props.user.email || 'Not Available'}
                </p>
            </div>
            <div className="card-footer">
                <a className={"btn btn-primary"} href={`/techs/${props.user.id}`}>View</a> &nbsp;
                <a className={"btn btn-secondary"} href={`/techs/${props.user.id}.vcf`}>Download</a>
            </div>
        </div>
    </div>
}

export default UserCard;