import {Controller} from 'stimulus'

export default class extends Controller {

    connect() {
        this.hideAndUnhideNodes()
    }

    // Read the current customer history filter page and return the
    // titles of each of the navigation tabs in the filter area.
    getNavBarNames() {
        let navBar = document.getElementById("history-filter-nav-tabs")
        let navBarText = this.getFilterNames(Array.from(navBar.innerText.trim()))
        let navBarNames = []
        navBarText.forEach(tabText => navBarNames.push(this.convertTabTextToTabName(tabText)))
        return navBarNames
    }


    // Given a string with the inner text of all the filter navigation
    // tabs that are visible on the screen, return an array of tab names.
    getFilterNames = (str) => {
        let newStr = str.join('').split(`\n`)
        let noSpaceArr = []
        newStr.forEach(word => noSpaceArr.push(word.trim()))
        return noSpaceArr.filter(item => item !== '')
    }


    // change a string like "Work Order Types" to "work-order-types"
    convertTabTextToTabName(tabText) {
        return tabText.toLowerCase().trim().replace(/\s+/g, '-')
    }

    // Look at each row and card on a page.  If it should be hidden based off
    // a class assigned to it (eg. hide-priority, hide-work-order-type, hide-route-type),
    // then hide it.  Otherwise, show it.
    hideAndUnhideNodes() {
        let allNodes = Array.from(document.querySelectorAll(`.result-row`)).concat(
            Array.from(document.querySelectorAll(`.result-card`)))
        allNodes.forEach( node => {
            if (Array.from(node.classList).join(" ").match(/(?:hide-)\S/)) {
                node.classList.add("d-none")
            } else {
                node.classList.remove("d-none")
            }
        })
    }


    // When the "Select All" button is clicked, check every checkbox
    // and make sure the corresponding work orders are displayed.
    selectAll = (e) => {
        let allCheckboxes = Array.from(e.target.parentElement.parentElement.querySelectorAll('input[type=checkbox]'))
        allCheckboxes.forEach(checkbox => checkbox.checked = true)
        this.showAllOptions(e)
    }


    // When the "Unselect All" button is clicked, uncheck every checkbox
    // and make sure the corresponding work orders are hidden.
    unselectAll = (e) => {
        let allCheckboxes = Array.from(e.target.parentElement.parentElement.querySelectorAll('input[type=checkbox]'))
        allCheckboxes.forEach(checkbox => checkbox.checked = false)
        this.hideAllOptions(e)
    }


    // After toggling all checkboxes for selection, make sure that each of the
    // possible options are actually showing.  Do this only for whatever tab (priorities,
    // work-order-types, or route-types) is currently selected.
    showAllOptions = (e) => {
        let filterName = this.getButtonFilterName(e)
        let nodes = Array.from(document.querySelectorAll(`.result-row`)).concat(
            Array.from(document.querySelectorAll(`.result-card`)))
        nodes.forEach( node => node.classList.remove(`hide-${filterName}`))
        this.hideAndUnhideNodes()
    }


    // After toggling all checkboxes off, make sure that each of the
    // possible options are hidden.  Do this only for whatever tab (priorities,
    // work-order-types, or route-types) is currently selected.
    hideAllOptions = (e) => {
        let filterName = this.getButtonFilterName(e)
        let nodes = Array.from(document.querySelectorAll(`.result-row`)).concat(
            Array.from(document.querySelectorAll(`.result-card`)))

        nodes.forEach( node => node.classList.add(`hide-${filterName}`))
        this.hideAndUnhideNodes()
    }


    // Returns the text of the name of the filter ("priority, work-order-type, route-type")
    // given a click event on a checkbox.
    getFilterName = (e) => {
        let filterId = e.target.parentElement.parentElement.parentElement.id
        return this.removeLastTag(filterId)
    }

    // Returns the text of the name of the filter ("priority, work-order-type, route-type")
    // given a click event on a select/unselect all button.
    getButtonFilterName = (e) => {
        let filterId = e.target.parentElement.parentElement.id
        return this.removeLastTag(filterId)
    }


    // Id's for different sections of the partial include strings
    // such as "priority-content" and "priority-tab".  Here, we would want
    // to return only "priority" for either of those two strings.
    removeLastTag = (str) => {
        let strArray = str.split('-')
        let shortenedArray = strArray.slice(0,-1)
        let result = shortenedArray.join('-').trim()
        return result
    }


    // When a single checkbox is checked or unchecked, find the associated work
    // orders and either show or hide them.
    toggleSingleFilter = (e) => {
        let filterName = this.getFilterName(e)
        let filterNameForCards = filterName + "-card"
        let filterText = e.target.parentElement.innerText?.trim()
        let checked = e.target.checked

        let filteredCards = Array.from(document.querySelectorAll(`.result-card`))
            .filter( card => card.querySelector(`[data-id=${CSS.escape(filterNameForCards)}]`)
                    ?.innerText.trim() === filterText)
        let filteredRows = Array.from(document.querySelectorAll(`.result-row`))
            .filter( row => row.querySelector(`[data-id=${CSS.escape(filterName)}]`)
                    ?.innerText.trim() === filterText )
        let nodesToToggle = filteredRows.concat(filteredCards)
        if (checked === true) {
            nodesToToggle.forEach( node => {
                node.classList.remove(`hide-${filterName}`)
            })
        } else {
            nodesToToggle.forEach( node => {
                node.classList.add(`hide-${filterName}`)
            })
        }
        this.hideAndUnhideNodes()
    }
}