import { Controller } from 'stimulus'

export default class extends Controller {
    connect() {
        let dropdowns = document.getElementsByTagName('li')

        if(dropdowns === undefined || dropdowns[0] === undefined){
            return;
        }

        if(dropdowns.length > 1) {
            let last_dropdown = dropdowns.length - 2

            if(dropdowns[last_dropdown].getElementsByClassName('dropdown-menu')[0] !== undefined) {
                dropdowns[last_dropdown].getElementsByClassName('dropdown-menu')[0].classList.add("dropdown-menu-end");
            }
        } else{
            if(dropdowns[0].getElementsByClassName('dropdown-menu')[0] !== undefined) {
                dropdowns[0].getElementsByClassName('dropdown-menu')[0].classList.add("dropdown-menu-end");
            }
        }
    }
}