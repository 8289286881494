import { Controller } from 'stimulus'
import swal from 'sweetalert2'
import Rails from 'rails-ujs'

export default class extends Controller {
  static targets = ['form', 'complete', 'submit', 'select', 'price', 'woid',
                    'customfields', 'customcard', 'customlayout', 'opentasks', 'date', 'description']

  connect() {
    this.typeSelect()
  }

  complete(event) {
    event.preventDefault();
    if (this.completeTarget.value == 'true') {
      var btnText = this.submitTarget.value
      this.submitTarget.value = 'Please Wait...'
      new swal({
        customClass: 'modal',
        icon: 'warning',
        title: 'Alert',
        html: this.warning_text,
        showCancelButton: true
      }).then((result) => {
        if (result.value) {
          if (this.dateTarget.value != '' && this.descriptionTarget.value != '') {
            new swal('Complete', 'Work Order Has Been Marked Complete', 'success')
            Rails.fire(this.formTarget, 'submit');
          }
          else {
            new swal('Error', 'Invalid Form Values', 'error')
            Rails.fire(this.formTarget, 'submit');
          }
        } else if (result.dismiss === swal.DismissReason.cancel) {
          new swal('Cancelled', 'Returning to edit work log', 'error');
        }
        this.submitTarget.removeAttribute('disabled');
        this.submitTarget.value = btnText;
      });
    } else {
      Rails.fire(this.formTarget, 'submit')
    }
  }

  get warning_text() {
    var messageText = 'Are you sure you want to complete this work order? The Work Log ' +
    'description will become the completion comment for the work order.'
    if (+this.opentasksTarget.value > 0) {
      messageText += '<br><br><b>This work order has open tasks!</b>'
    }
    return messageText
  }

  typeSelect() {
    var xhr = new XMLHttpRequest();
    var self = this
    var wo_id = this.woidTarget.value
    if(wo_id == ''){ wo_id = -1 }
    xhr.open('GET', '/service/work_order/'+ wo_id +'/type')
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    xhr.onload = function() {
      if (xhr.status == 200) {
        self.customcardTarget.removeAttribute('hidden')
        eval(xhr.responseText)
      } else {
        self.customcardTarget.setAttribute('hidden', "true")
        self.customfieldsTarget.innerHTML = ""
      }
    }
    xhr.send()
  }

  toggleCustom(e) {
    if (this.customlayoutTarget.value === 'false') {
      this.customfieldsTarget.style.position = 'static';
      return; 
    }
    setTimeout(() => {
      if (!(e.target.id === 'customCardHeader')) { return; }
      if (this.customfieldsTarget.style.height) {
        this.customfieldsTarget.style.position = 'relative';
        this.customcardTarget.style.height = `${this.customfieldsTarget.scrollHeight}px`;
        this.customfieldsTarget.style.height = `${this.customfieldsTarget.scrollHeight}px`;
      } else {
        this.customcardTarget.style.height = this.customCardHeight;
      }
    });
  }
}
