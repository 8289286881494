import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['unitBalance', 'unitAddedBalance', 'unitRejectBalance',
                    'unitPreviousBalance', 'finalBalance', 'previousBalance',
                    'rejectedBalance', 'loadedBalance', 'plannedLoadBalance']

  calculate() {
    this.previousBalanceTarget.value = this.calculatedPreviousBalance()
    this.loadedBalanceTarget.value = this.calculatedAddedBalance()
    this.rejectedBalanceTarget.value = this.calculatedRejectedBalance()
    this.finalBalanceTarget.value = this.calculatedFinalBalance()
    this.borderColor()
  }

  calculatedPreviousBalance() {
    return this.calculatedBalance(this.unitPreviousBalanceTargets)
  }

  calculatedAddedBalance() {
    return this.calculatedBalance(this.unitAddedBalanceTargets) * -1
  }

  calculatedRejectedBalance() {
    return this.calculatedBalance(this.unitRejectBalanceTargets)
  }

  calculatedFinalBalance() {
    return this.calculatedBalance(this.unitBalanceTargets)
  }

  borderColor() {
    var planned_amount = parseInt(this.plannedLoadBalanceTarget.value)
    var load_fields = document.getElementsByClassName('load-count')
    if (planned_amount == this.calculatedAddedBalance()) {
      document.getElementsByTagName('body')[0].style.border = 'solid 0.9em green'
      for (let i = 0; i < load_fields.length; i++){
        load_fields[i].style.backgroundColor = 'rgb(0, 255, 0, 0.25)'
      }
    } else {
      document.getElementsByTagName('body')[0].style.border = 'solid 0.9em red'
      for (let i = 0; i < load_fields.length; i++){
        load_fields[i].style.backgroundColor = 'rgb(255, 1, 0, 0.25)'
      }
    }
  }

  calculatedBalance(array) {
    var amount = 0
    var count = array.length

    for(var i = 0; i < count; i++) {
      var unitAmount = parseInt(array[i].value)
      if (!isNaN(unitAmount)) {
        amount += unitAmount
      }
    }

    return amount;
  }
}
