import { Controller } from 'stimulus'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['startDate', 'endDate', 'search', /*'results',*/ 'cardResults' ]

  date_options_to = { dateFormat: 'm/d/Y', ariaDateFormat: 'm/d/Y', defaultDate: [new Date().setFullYear(new Date().getFullYear() - 2)] }
  date_options_from = { dateFormat: 'm/d/Y', ariaDateFormat: 'm/d/Y', defaultDate: [new Date()] }
  date_options = { dateFormat: 'm/d/Y', ariaDateFormat: 'm/d/Y' }

  initialize() {
    this.initialFilter();
  }

  get query() {
    return this.searchTarget.value.toLowerCase();
  }

  tableRows(id) {
    return document.getElementById(id).getElementsByTagName("tr");

  }

  get cards() {
    return document.getElementsByClassName('result-card');
  }

  connect() {
    this.initialize_flatpickr();
  }

  filter() {
    var startDate = this.stringToDate(this.startDateTarget.value)
    var endDate = this.stringToDate(this.endDateTarget.value)

    this.filterTable(startDate, endDate, 'table-results-wo')
    this.filterTable(startDate, endDate, 'table-results-ie')
    this.filterTable(startDate, endDate, 'table-results-opp')
    this.filterCards(startDate, endDate)
  }

  filterCards(startDate, endDate) {

    const cards = this.cards;

    for(let i=0; i < cards.length;i++)
    {
      let card = cards[i];
      let show = true;
      let createdDate = this.stringToDate(card.getElementsByClassName('created-date')[0].innerHTML.substring(0, 10));
      let value = cards[i].querySelector('.card-body').innerText.toLowerCase()

      // filter out rows before start date
      if (startDate && createdDate < startDate) {
        show = false
      }

      // filter out rows after start date
      if (endDate && endDate < createdDate) {
        show = false
      }

      // filter on search query
      if (this.query) {
        if (value.indexOf(this.query) <= -1) {
          show = false
        }
      }

      if (show) {
        card.style.display = "";
      } else {
        card.style.display = "none"
      }
    }
  }

  filterTable(startDate, endDate, id) {
    if (document.getElementById(id) != null) {
      var rows = this.tableRows(id);
      var hide = true

      for (var i = 0; i < rows.length; i++) {
        var cells = rows[i].getElementsByTagName("td");
        var rowDate = this.stringToDate(cells[1].innerHTML.replace(/\s/g, '').substring(0, 10))
        var value = ""
        var show = true

        for (var td = 0; td < cells.length; td++) {
          value += cells[td].innerHTML.toLowerCase();
        }

        // filter out rows before start date
        if (startDate && rowDate < startDate) {
          show = false
        }

        // filter out rows after start date
        if (endDate && endDate < rowDate) {
          show = false
        }

        // filter on search query
        if (value.indexOf(this.query) <= -1) {
          show = false
        }

        if (show) {
          rows[i].style.display = "";
          hide = false
        } else {
          rows[i].style.display = "none"
        }
      }
      if (window.location.href.indexOf("/history") > -1) {
        this.hideSections(id, hide)
      }
    }
  }

  stringToDate(stringDate) {
    var val;
    var parts = stringDate.split('/');
    var date = new Date(parts[2], parts[0], parts[1]);
    if (!isNaN(date.getTime())) {
      val = date;
    }

    return val;
  }

  hideSections(id, hide) {
    if (id == 'table-results-wo' ){
      document.getElementById("work-orders").hidden = hide;
    }
    else if ( id == 'table-results-ie' ){
      document.getElementById("interaction-events").hidden = hide;
    }
    else if ( id == 'table-results-opp' ){
      document.getElementById("opportunities").hidden = hide;
    }
  }

  initialFilter(){
    if (this.route_check() === 'history'){
      var currentDate = new Date()
      var pastYear = currentDate.getFullYear() - 2
      var day = String(currentDate.getDate()).padStart(2, '0')
      var pastDay = String(new Date(new Date().setFullYear(pastYear)).getDate()).padStart(2, '0')
      var month = String(currentDate.getMonth() + 1).padStart(2, '0')
      var year = currentDate.getFullYear();
      var startDate = this.stringToDate(month + '/' + pastDay + '/' + pastYear)
      var endDate = this.stringToDate(month + '/' + day + '/' + year)

      this.filterTable(startDate, endDate, 'table-results-wo')
      this.filterTable(startDate, endDate, 'table-results-ie')
      this.filterTable(startDate, endDate, 'table-results-opp')
      this.filterCards(startDate, endDate)
    }
  }

  initialize_flatpickr() {
    if (this.route_check() === 'history') {
      flatpickr(this.startDateTarget, this.date_options_to);
      flatpickr(this.endDateTarget, this.date_options_from);
    } else {
      flatpickr(this.startDateTarget, this.date_options);
      flatpickr(this.endDateTarget, this.date_options);
    }
  }

  route_check() {
    if (window.location.href.indexOf("/customers") > -1 && window.location.href.indexOf("/history") > -1) {
      return 'history'
    } else {
      return 'default'
    }
  }
}
