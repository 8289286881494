import { Controller } from 'stimulus'
import Rails from 'rails-ujs'
import Timr from 'timrjs'
import swal from 'sweetalert2'

export default class extends Controller {
  static targets = ['switch', 'timer', 'progressBar', 'srProgressBar',
                    'minutes', 'setTimerButton', 'form', 'remainingTime',
                    'disarmPin', 'lengthInMinutes']

  time = '15:00'

  connect() {
    if (this.remainingTimeTarget.value != "") {
      this.time = this.remainingTimeTarget.value
    }
    this.setup_timer();
    if (this.remainingTimeTarget.value != "") {
      this.start_timer();
    }
  }

  display_time_set() {
    document.getElementById('set-time-modal').modal();
  }

  set_timer() {
    this.time = this.minutesTarget.value + ":00"
    this.lengthInMinutesTarget.value = this.minutesTarget.value
    this.timerTarget.innerHTML = this.time
    this.connect()
  }

  timer_action() {
    if (this.switchTarget.innerHTML == 'Start') {
      Rails.fire(this.formTarget, 'submit')
      this.start_timer();
    } else if (this.switchTarget.innerHTML == 'Stop') {
      this.stop_timer();
    }
  }

  start_timer() {
    this.setTimerButtonTarget.style.display = 'none'
    this.switchTarget.innerHTML = 'Stop';
    this.timer.start()
  }

  stop_timer() {
    new swal({
      title: 'Enter Pin to Stop Timer',
      input: 'number',
      showCancelButton: true,
      confirmButtonText: 'Stop',
      preConfirm: (pin) => {
        return new Promise((resolve) => {
          setTimeout(() => {
            if (pin != this.disarmPinTarget.value) {
              swal.showValidationError('Invalid Pin')
            }
            resolve()
          }, 1000)
        })
      },
      allowOutsideClick: () => !swal.isLoading()
    }).then((result) => {
        if (result.value) {
          this.time = '15:00'
          this.timerTarget.innerHTML = this.time
          new swal({ icon: 'success', title: 'Timer Stopped' })
          this.setTimerButtonTarget.style.display = 'block'
          this.switchTarget.innerHTML = 'Start';
          this.timer.stop();
          this.setup_timer();
          Rails.fire(this.formTarget, 'submit')
        }
    })
  }

  update_progressbar(percent) {
    var style = 'width: ' + percent + '%;';

    this.progressBarTarget.setAttribute('style', style);
    this.progressBarTarget.setAttribute('class', this.progressbar_class(percent));
    this.progressBarTarget.setAttribute('aria-valuenow', percent);
    this.srProgressBarTarget.innerHTML = percent + "%";
  }

  progressbar_class(percent) {
    var css = ''

    if (percent <= 75) {
      css = 'progress-bar progress-bar-striped progress-bar-success active';
    } else if (percent <= 90 && percent >= 76) {
      css = 'progress-bar progress-bar-striped progress-bar-warning active';
    } else if (percent >= 91) {
      css = 'progress-bar progress-bar-striped progress-bar-danger active';
    } else {
      css = 'progress-bar progress-bar-striped progress-bar-success active';
    }

    return css;
  }

  setup_timer() {
    this.timer = Timr(this.time).ticker(({formattedTime, percentDone }) => {
      this.update_progressbar(percentDone);
      this.timerTarget.innerHTML = formattedTime
    }).onStop(self => {
      this.update_progressbar(0);
      this.timerTarget.innerHTML = this.time;
    }).finish(self => {
      new swal('Time Expired');
      this.update_progressbar(0);
      this.setTimerButtonTarget.style.display = 'block'
      this.switchTarget.innerHTML = 'Start';
      this.timerTarget.innerHTML = this.time;
    })
  }
}

