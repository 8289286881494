import { Controller } from 'stimulus'
import swal from 'sweetalert2'
import Rails from "rails-ujs";

export default class extends Controller {
  static targets = ['button']

  display_alert(event) {
    new swal({
      customClass: 'modal',
      icon: 'warning',
      title: 'Alert',
      html: 'This is not our machine. Would you like to continue making a load?',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        this.buttonTarget.click();
        result.dismiss;
      }});
  }
}
