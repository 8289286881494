import { Controller } from 'stimulus'
import { connect } from 'net';
import swal from 'sweetalert2'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = ['select', 'link', 'customfields', 'customcard', 'woid', 'customer',
  'customlayout', 'inactive', 'ourCustomer', 'startDate']
  static customCardHeight;

  connect() {
    this.customCardHeight = this.customcardTarget.style.height;
    this.checkInactive();
    this.typeSelect()
    this.initializeFlatpickr()
  }

  typeSelect() {
    var custom_wo_id = this.selectTarget.value
    if(custom_wo_id == '') { return }
    
    var xhr = new XMLHttpRequest();
    var self = this
    var wo_id = this.woidTarget.value
    var customer = this.customerTarget.value
    if(wo_id == ''){ wo_id = -1 }
    xhr.open('GET', '/service/work_order/'+ wo_id +'/type/'+custom_wo_id+'/customer/'+customer)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    xhr.onload = function() {
      if (xhr.status == 200) {
        self.customcardTarget.removeAttribute('hidden')
        eval(xhr.responseText)
      } else {
        self.customcardTarget.setAttribute('hidden', "true")
        self.customfieldsTarget.innerHTML = ""
      }
    }
    xhr.send()
  }

  toggleCustom(e) {
    if (this.customlayoutTarget.value === 'false') {
      this.customfieldsTarget.style.position = 'static';
      return; 
    }
    setTimeout(() => {
      if (!(e.target.id === 'customCardHeader')) { return; }
      if (this.customfieldsTarget.style.height) {
        this.customfieldsTarget.style.position = 'relative';
        this.customcardTarget.style.height = `${this.customfieldsTarget.scrollHeight}px`;
        this.customfieldsTarget.style.height = `${this.customfieldsTarget.scrollHeight}px`;
      } else {
        this.customcardTarget.style.height = this.customCardHeight;
      }
    });
  }

  checkInactive() {
    if (this.inactiveTarget && this.inactiveTarget.value === 'false') {
      new swal({
        icon: 'warning',
        title: 'Warning',
        text: 'This customer is marked as inactive'
      })
    }
    if (this.ourCustomerTarget && this.ourCustomerTarget.value === 'false') {
      new swal({
        icon: 'warning',
        title: 'Warning',
        text: 'This customer is not a current customer'
      })
    }
  }

  initializeFlatpickr() {
    let selectStartDateDiv = document.getElementById('selectStartDate')
    let defaultStartDateDiv = document.getElementById('defaultStartDate')
    if (defaultStartDateDiv.value) {
      var defaultDate = new Date(defaultStartDateDiv.value)
    } else {
      var defaultDate = null
    }
    let date_options = { dateFormat: 'm/d/Y', ariaDateFormat: 'm/d/Y' , defaultDate: defaultDate }
    flatpickr( selectStartDateDiv, date_options );
  }
}
