// This controller's purpose is to highlight in the results section
// all instances of the searched terms.  All result sections that are
// to be searched need to be located inside a dedicated <div> with
// the class "result".

import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['searchTerms', 'result']
    static values = { terms: Array }

    // Called upon submission of the form, it looks for results every half
    // second for up to 10 seconds.  When results are present, it determines what
    // terms should be highlighted and then sends all the
    // results areas to another function to receive highlighting.
    // This is running for 10 seconds because I was unable to determine when the
    // results were loaded, especially if a search had already been performed
    // so there were "result" divs present.
    async highlight() {
        let resultTargets = Array.from(document.querySelectorAll('.result'))
        for (let j=0; j<20; j++) {
            await new Promise(resolve => setTimeout(resolve, 500))
            resultTargets = Array.from(document.querySelectorAll('.result'))
            this.termsValue = Array.from(new Set(this.searchTermsTarget.value.split(' ')))  // removes duplicates
            this.reshuffleSearchTerms()  // sorts from longest to shortest
            resultTargets.forEach( target => this.highlightSubsection(target))
        }
    }

    // Given a subsection of HTML, find all instances of each of the search words
    // in it and highlight them by replacing the div with a new div containing highlights.
    highlightSubsection(target) {
        if (target.innerHTML.includes("<mark>")) {   // Avoid duplicate <mark> from running function multiple times
            return
        }
        target.innerHTML = "<mark></mark>" + target.innerHTML  // "mark" all target areas that have been scanned to avoid scanning same area twice
        for (let i=0; i<this.termsValue.length; i++) {
            if (this.termsValue[i].trim() === "") {
                continue
            }
            let regex = new RegExp(`(?![^<]*>)${this.termsValue[i]}`, 'gi');
            target.innerHTML = target.innerHTML.replace(regex, match => `<mark>${match}</mark>`)
        }
    }

    // Any term that is completely enclosed inside another term needs to be listed
    // after the longer term.  We hack this by sorting by length - longest terms to the
    // start of the array.
    reshuffleSearchTerms() {
        let originalArray = [...this.termsValue]
        if (originalArray.length <= 1) {
            return
        }

        let sortedArray = originalArray.sort((a,b) => {
            if (a.length > b.length) {
                return -1
            } else {
                return 0
            }})
        this.termsValue = sortedArray
    }
}