import { Controller } from 'stimulus'

export default class extends Controller {

  initialize(){
    let image1 = document.getElementById('image1').style.display = 'none'
    let image2 = document.getElementById('image2').style.display = 'none'
    let image3 = document.getElementById('image3').style.display = 'none'
    let image4 = document.getElementById('image4').style.display = 'none'
  }

  addFile(event) {
    // Html Area File Nodes
    let image = document.getElementById('image')
    let image1 = document.getElementById('image1')
    let image2 = document.getElementById('image2')
    let image3 = document.getElementById('image3')
    let image4 = document.getElementById('image4')
    let images = [image, image1, image2, image3, image4]

    for(let i = 0; i < images.length; i++){
      if(i != 0){
        this.hideCheck(i, images)
      }
    }
  }

  hideCheck(image_num, images){
    if((images[image_num].value != '' || images[image_num - 1].value != '')){
      images[image_num].style.display = ''
    }
    else{
      images[image_num].style.display = 'none'
    }
  }
}
