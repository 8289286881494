import { Controller } from 'stimulus'

// Controller Used to Control Functionality of Customer Notes & Their Headers
export default class extends Controller {
  static targets = ['search', 'note', 'headerFilter']

  // Changes New Note Head Form Field to be in sync w/ Header Filter
  syncHeaders () {
    var headerFilter = this.headerFilterTarget;
    var headerTarget = document.getElementById('add-note-header');
    var selectValue = headerFilter.value

    if (!selectValue)
    {
      headerTarget.value = 1;
      return;
    }

    this.clearAddNoteDescription();
    headerTarget.value = selectValue;
  }

  // Clears Description of Customer Note Add Note when closed w/ header changed
  clearAddNoteDescription () {
    var noteDescription = document.getElementById('add-note-description');

    noteDescription.value = '';
  }

  // Clears Description of Customer Note on Canceled Edit
  clearEditNoteDescription = (e) => {
    var noteCard = e.target.parentElement.parentElement
    var viewNoteDescription = noteCard.getElementsByClassName('view-description-box')[0]
    var editNoteDescription = noteCard.getElementsByClassName('edit-description-box')[0]

    if(viewNoteDescription === null)
    {
      editNoteDescription.value = '';
      return
    }
    if (editNoteDescription === null)
        return;

    editNoteDescription.value = viewNoteDescription.innerText;
  }

  // Triggers both the filter & search notes features, called to dual filter notes
  filterAndSearchNotes() {
    var noteCards = this.noteTargets;

    this.filterNotes(noteCards)
    this.searchNotes(noteCards)
  }

  // Filters Note Cards to Match Header Filter
  filterNotes(noteCards) {
    let filterHeaderId = this.headerFilterTarget.value;

    if (!filterHeaderId || filterHeaderId === '') {
      this.showAllNotes(noteCards);
      return;
    }

    this.hideNotes(noteCards, filterHeaderId);
  }

  // Displays all customer notes
  showAllNotes (notes) {
    for (var n = 0; n < notes.length; n++ ) {
      notes[n].parentElement.parentElement.classList.remove('hide')
    }
  }

  // Displays all customer notes, applies hide class to all notes not under selected header
  hideNotes (notes, filterId) {
    for (var n = 0; n < notes.length; n++ ) {
      if (notes[n].getElementsByClassName('card-note-header')[0].getAttribute('value') === filterId) {
        notes[n].parentElement.parentElement.classList.remove('hide');
      }
      else {
        notes[n].parentElement.parentElement.classList.add('hide');
      }
    }
  }

  // Will search through all notes for key search words, will apply d-none class to all notes that don't
  // contain search
  searchNotes(noteCards) {
    for(var i = 0; i < noteCards.length; i++) {
      var headerValue = noteCards[i].querySelector('.card-note-header').innerText.toLowerCase()
      var descrValue = noteCards[i].querySelector('.view-description-box').innerText.toLowerCase()

      if (headerValue.indexOf(this.query) > -1 || descrValue.indexOf(this.query) > -1) {
        noteCards[i].parentElement.parentElement.classList.remove("d-none")
      } else {
        noteCards[i].parentElement.parentElement.classList.add("d-none")
      }
    }
  }

  get query() {
    return this.searchTarget.value.toLowerCase();
  }
}
