import flatpickr from 'flatpickr'

export function loadDates() {
    var date_options = { dateFormat: 'm/d/Y', ariaDateFormat: 'm/d/Y' }
    var time_options = { enableTime: true, noCalendar: true, dateFormat: 'h:i K',
        minuteIncrement: 15}

    flatpickr('#sales_opportunity_target_date', date_options);
    flatpickr('#sales_close_opportunity_close_date', date_options);
    flatpickr('#sales_interaction_event_interaction_date', date_options);
    flatpickr('#date', date_options);
    flatpickr('#service_work_order_start_date', date_options);
    flatpickr('#service_work_log_work_date', date_options);
    flatpickr('#service_task_format_date', date_options);
    flatpickr('#sales_interaction_event_strappttime', time_options);
    flatpickr('#time', time_options);
}