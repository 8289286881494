// This controller handles whether we are going to be seeing a card-view or
//  a grid-view and the toggling between those two views.

import {Controller} from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
    static targets = ['dataGridBtn', 'cardsBtn', 'dataGrid', 'card']

    // property to store the view that is selected
    view;

    connect() {
        this.loadSettings();
        this.selectView();

        window.addEventListener("force-reload", () => {
            this.selectView()
        });
    }

    // Gets Called on window.resize
    resize() {
        this.selectView()
    }

    // Called when Grid Button is clicked
    dataGridClick() {
        this.displayGrid()
        this.saveSettings('grid')
    }

    // Called when Grid Button is clicked
    cardClick() {
        this.displayCards()
        this.saveSettings('card');
    }

    // selects the view
    selectView() {
        if (window.innerWidth < 600) {
            this.displayCards()
        } else {
            this.view == 'grid' ? this.displayGrid() : this.displayCards()
        }
    }

    // displays the Table View
    displayGrid() {
        this.turnOnTableViewBtn()
        this.hideCards()
        var grids = this.dataGridTargets
        for (var i = 0; i < grids.length; i++) {
            grids[i].classList.remove('hide')
        }
    }

    // Displays the Card View
    displayCards() {
        this.turnOnCardViewBtn()
        this.hideDataGrid()
        var cards = this.cardTargets
        for (var i = 0; i < cards.length; i++) {
            cards[i].classList.remove('hide')
        }
    }

    // Hides Data Grid
    hideDataGrid() {
        var grids = this.dataGridTargets
        for (var i = 0; i < grids.length; i++) {
            grids[i].classList.add('hide')
        }
    }

    // Hides Cards
    hideCards() {
        var cards = this.cardTargets
        for (var i = 0; i < cards.length; i++) {
            cards[i].classList.add('hide')
        }
    }

    //turns on Card View Button
    turnOnCardViewBtn() {
        this.cardsBtnTarget.classList.remove('btn-white')
        this.cardsBtnTarget.classList.add('btn-secondary')
        this.dataGridBtnTarget.classList.add('btn-white')
        this.dataGridBtnTarget.classList.remove('btn-secondary')
    }

    //turns on Data Grid Button
    turnOnTableViewBtn() {
        this.cardsBtnTarget.classList.add('btn-white')
        this.cardsBtnTarget.classList.remove('btn-secondary')
        this.dataGridBtnTarget.classList.remove('btn-white')
        this.dataGridBtnTarget.classList.add('btn-secondary')
    }

    // Loads View Settings from Cookies
    loadSettings() {
        var val = Cookies.get('view')
        if (val == NaN) {
            this.view = 'grid'
        } else {
            this.view = val
        }
    }

    // Saves Settings to Cookies
    saveSettings(v) {
        Cookies.set('view', v, {expires: 1000})
        this.view = v
    }
}
