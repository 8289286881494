import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['grandTotal', 'cassetteTotal']

  calculate() {
    this.grandTotalTarget.value = this.calculatedBalance(this.cassetteTotalTargets)
  }

  calculatedBalance(array) {
    var amount = 0
    var count = array.length

    for(var i = 0; i < count; i++) {
      var unitAmount = parseInt(array[i].value)
      if (!isNaN(unitAmount)) {
        amount += unitAmount
      }
    }
    return amount
  }
}
