import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['mainImage']

  changeImage(event) {
    var src = event.currentTarget.src
    src.replace('thumb', 'medium')
    this.mainImageTarget.src = src
  }
}
