import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['qty', 'price', 'total']

  calculate_total() {
    var qty = this.zero_for_null(this.qtyTarget.value);
    var priceEach = this.zero_for_null(this.priceTarget.value);
    var total = qty * priceEach

    this.qtyTarget.value = qty
    this.priceTarget.value = priceEach
    this.totalTarget.value = total
  }

  zero_for_null(value) {
    var rtn = 0;
    if (value) {
      rtn = value;
    }
    return rtn;
  }
}
