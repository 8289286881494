import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'search', 'tbody' ]

  filter() {
    var rows = this.tableRows;
    for(var i = 0; i < rows.length; i++) {
      var cells = rows[i].getElementsByTagName("td");
      var value = ""

      for(var td = 0; td < cells.length; td++) {
        value += cells[td].innerText.toLowerCase();
      }

      if (value.indexOf(this.query) > -1) {
        rows[i].style.display = "";
      } else {
        rows[i].style.display = "none"
      }
    }
  }

  get query() {
    return this.searchTarget.value.toLowerCase();
  }

  get tableRows() {
    return this.tbodyTarget.getElementsByTagName("tr");
  }
}
