import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['customfields', 'customcard', 'customlayout']
  static customCardHeight;
  connect() {
    this.customCardHeight = this.customcardTarget.style.height;
  }

  toggleCustom(e) {
    if (this.customlayoutTarget.value === 'false') {
      this.customfieldsTarget.style.position = 'static';
      return; 
    }
    setTimeout(() => {
      if (!(e.target.id === 'customCardHeader')) { return; }
      if (this.customfieldsTarget.style.height) {
        this.customfieldsTarget.style.position = 'relative';
        this.customcardTarget.style.height = `${this.customfieldsTarget.scrollHeight}px`;
        this.customfieldsTarget.style.height = `${this.customfieldsTarget.scrollHeight}px`;
      } else {
        this.customcardTarget.style.height = this.customCardHeight;
      }
    });
  }
}