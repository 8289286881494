import swal from 'sweetalert2'
import { Controller } from 'stimulus'

export default class WorkOrderAssignment extends Controller {
  static targets = ['machineId', 'routeTypeId', 'customerId']

  set_previous_route_type_id() {
    if (this.hasRouteTypeIdTarget) {
      this.previousRouteTypeId = this.routeTypeIdTarget.value
    }
  }

  // Alerts User that Route Is Being Changed on WO form, but only if machine is selected
  route_select() {
    if (this.hasRouteTypeIdTarget) {
      let change_text = 'You are about to change the route for this ' +
          'work order. This could change who is assigned to the work order.'


      if (this.routeTypeIdTarget && this.routeTypeIdTarget.getAttribute('data-notice') == 'true') {
        new swal({
          icon: 'warning',
          title: 'Warning',
          text: change_text,
        }).then((result) => {
          new swal({
            icon: 'success',
            title: 'Route Type Changed',
            text: 'Check to ensure the proper technicians are notified'
          })
        })
      }
    }
  }

  // Will enable the alerts on route change once a machine is selected and autofills Route Type Field
  machine_select() {
    if (this.routeTypeIdTarget) {
      this.routeTypeIdTarget.dataset.notice = 'true'
    }
    let id = this.machineIdTarget.value;
    if (!id) {
      return
    }

    let xhr = new XMLHttpRequest();
    xhr.open('GET', `/change_machine/${id}`)
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
    xhr.onload = function() {
      if (xhr.status == 200) {
        eval(xhr.responseText)
      }
    }
    xhr.send();
  }
}
