import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['selectBox', 'hiddenFieldArea', 'tagArea']

    techs = []


    connect() {
        let xhr = new XMLHttpRequest();
        let self = this
        xhr.open('GET', '/techs/list')
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        xhr.onload = function() {
            if (xhr.status === 200) {
                self.techs = JSON.parse(xhr.responseText)

                let preSelected = self.hiddenFieldAreaTarget.children

                for(let i=0;i<preSelected.length;i++)
                {
                    const tech = self.techs.find(q => q.id === parseInt(preSelected[i].value))
                    self.tagAreaTarget.innerHTML = self.tagAreaTarget.innerHTML + self.techBadge(tech.name);
                }
            }
        }
        xhr.send()
    }

    addTech() {
        const tech = this.findTech(this.selectBoxTarget.value);
        if (tech != null && !this.userAssigned(tech.name)) {
            this.tagAreaTarget.innerHTML = this.tagAreaTarget.innerHTML + this.techBadge(this.selectBoxTarget.value);
            this.hiddenFieldAreaTarget.innerHTML = this.hiddenFieldAreaTarget.innerHTML + this.techHiddenField(tech.id)
        }
        this.selectBoxTarget.value = '';
    }

    removeTech(event) {
        const hiddenFields = this.hiddenFieldAreaTarget.children;
        const name = event.target.parentElement.innerText.substring(2);
        const tech = this.findTech(name);

        for(let i=0;i<hiddenFields.length;i++)
        {
            const item = hiddenFields[i];

            if (item.value == tech.id.toString()) {
                item.remove()
            }
        }

        event.target.parentElement.remove();
    }

    userAssigned(name) {
        let value = false;
        const children = this.tagAreaTarget.children

        for(let i=0;i<children.length;i++)
        {
            value = children[i].innerText.includes(name);
            if (value === true) {
                break;
            }
        }

        return value;
    }

    findTech(name) {
        return this.techs.find(q => q.name === name)
    }

    techBadge(name) {
        return `<span class="badge bg-primary"><a data-action="click->tech-select#removeTech" class="remove-x">&times;</a> ${name}</span> &nbsp;`
    }

    techHiddenField(id) {
        return `<input type="hidden" name="sales_interaction_event[tech_id_values][]" alt="" value="${id}" />`
    }
}