// This controller generates the work orders for tech dynamically, build their html cards/tables
// and returns the html for use on page

import { Controller } from 'stimulus'

export default class extends Controller {

  generateWorkOrder = async (e) => {
    e.stopPropagation();

    if (e.target.classList.contains('work-orders-generated'))
      return

    let collapseId = e.target.id;
    let params = new URLSearchParams(window.location.search)
    let colSort = 'none'
    let dirSort = 'none'

    if(params.get('sort_column') !== null)
      colSort = params.get('sort_column');


    if(params.get('sort_direction') !== null)
      dirSort = params.get('sort_direction');

    let sortParams = '/' + colSort + '/' + dirSort;

    let cardSection = document.getElementsByClassName('card-area.' + collapseId)[0];
    let tableSection = document.getElementsByClassName('table-area.' + collapseId)[0];

    let cardHtml = ''
    let tableHtml = ''
    var xhr = new XMLHttpRequest();
    xhr.open('GET', `/generate_work_orders/${collapseId}/generate_tech_work_orders${sortParams}`)
    xhr.onload = function () {
      if (xhr.status == 200) {
        cardHtml = JSON.parse(xhr.responseText)[0];
        tableHtml = JSON.parse(xhr.responseText)[1];
        cardSection.innerHTML = cardHtml;
        tableSection.innerHTML = tableHtml;

        let event = new CustomEvent("force-reload");
        window.dispatchEvent(event);
      } else {
        console.log('Work Order Generator API failed to load properly.')
      }
    }
    xhr.send()

    e.target.classList.add('work-orders-generated');
  }
}