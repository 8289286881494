import * as React from 'react'
import UserCard from "./cards/UserCard";

function PhoneDirectoryCards(props) {

    const users = props.users ?? [];

    return (<div className="row">
        {users.map(x => {
            return <UserCard key={x.id}
        user={x} />
        })}
    </div>)
}

export default PhoneDirectoryCards;