import { Controller } from 'stimulus'
import swal from 'sweetalert2'

export default class extends Controller {
  static targets = ['inactive', 'ourCustomer']

  connect() {
    this.checkInactive();
  }

  checkInactive() {
    if (this.inactiveTarget && this.inactiveTarget.value === 'false') {
      new swal({
        icon: 'warning',
        title: 'Warning',
        text: 'This customer is marked as inactive'
      })
    }
    if (this.ourCustomerTarget && this.ourCustomerTarget.value === 'false') {
      new swal({
        icon: 'warning',
        title: 'Warning',
        text: 'This customer is not a current customer'
      })
    }
  }
}