import { Controller } from 'stimulus'
import swal from 'sweetalert2';

export default class extends Controller {
  static targets = ['reminders']

  connect() {
    let alerts = '';
    if (this.remindersTargets.length > 0) {
      for (const reminder of this.remindersTargets) {
        alerts += `${reminder.value}<br>`;
      }
      /*
      new swal({
        icon: 'warning',
        title: 'Reminders',
        html: alerts
      }); */
    }
  }
}